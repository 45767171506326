const ignoredElement = (child: any) => {
  return (
    child.nodeName === 'HorizontalLine' || // Visual separator in mosaic.
    child.nodeName === 'LineBreak' || // Visual separator in mosaic.
    child.nodeName === 'TableHeader' || // Only present on a RepeatingTable. Column values match question text so we could ignore them.
    child.nodeName === 'Help' || // Miles said ACG still has to decide on how to display help bubbles so for now we can skip these nodes
    child.nodeName === 'PageBreak' // Do we need to convert page breaks?
  );
};

const tryToConvertAsSimpleField = (child: any) => {
  let entity = {
    type: '',
    id: '',
    conditions: null,
    entities: null,
  };
  if (child.nodeName === 'Input') {
    if (child.getAttribute('questionUserCode')) {
      entity = {
        type: 'question',
        id: child.getAttribute('questionUserCode'),
        conditions: null,
        entities: null,
      };
    }
  } else if (child.nodeName === 'Text') {
    if (child.getAttribute('questionUserCode')) {
      entity = {
        id: child.getAttribute('questionUserCode'),
        // text: child.textContent ? child.textContent.trim() : '',
        type: 'textfield',
        conditions: null,

        entities: null,
      };
    }
  }

  return entity;
};

const convertChildSectionFields = (childSectionNode: any, parent?: any) => {
  const entities = [];
  for (const child of childSectionNode.children) {
    if (ignoredElement(child)) {
      continue;
    }
    const entity = tryToConvertAsSimpleField(child);
    if (entity.id != '') {
      entities.push(entity);
    } else {
      //  console.log('Unknown child section node: ' + child.nodeName + ' in ' + parent);
    }
  }
  return entities;
};

const convertFindPartyTableNode = (findPartyTableNode: any) => {
  return {
    info:
      findPartyTableNode.getAttribute('title').textContent != undefined
        ? findPartyTableNode.getAttribute('title').textContent
        : '',
    conditions: null,
    entities: convertChildSectionFields(findPartyTableNode, 'Find Party Table'),
    repeatable: false,
    repeatableTitle: '',
    numberOfNestedConditionals: null,
    skipToSummaryConditions: null,
  };
};

const convertPairedFieldsetNode = (pairedFieldsetNode: any) => {
  const entities = [];
  for (const child of pairedFieldsetNode.children) {
    if (child.nodeName === 'Help') {
      // Miles said ACG still has to decide on how to display help bubbles so for now we can skip these nodes
    } else if (child.nodeName === 'FieldPairing') {
      const childSecEntities = convertChildSectionFields(child, 'Paired Fieldset');
      for (let i = 0; i < childSecEntities.length; i++) {
        entities.push(childSecEntities[i]);
      }
    } else {
      // console.log('Unknown paired fieldset child node: ' + child.nodeName);
    }
  }

  return {
    info:
      pairedFieldsetNode.getAttribute('legend').textContent != undefined
        ? pairedFieldsetNode.getAttribute('legend').textContent
        : '',
    conditions: null,
    entities: entities,
    repeatable: false,
    repeatableTitle: '',
    numberOfNestedConditionals: null,
    skipToSummaryConditions: null,
  };
};

const convertRepeatingTable = (repeatingTableNode: any) => {
  return {
    conditions: null,
    repeatable: true,
    repeatableTitle: 'Insert section', // What should the repeating title be?
    info:
      repeatingTableNode.getAttribute('title').textContent != undefined
        ? repeatingTableNode.getAttribute('title').textContent
        : '',
    entities: convertChildSectionFields(repeatingTableNode, 'Repeating Table'),
    numberOfNestedConditionals: null,
    skipToSummaryConditions: null,
  };
};

const convertRepeatingSection = (repeatingSectionNode: any) => {
  return {
    conditions: null,
    repeatable: true,
    repeatableTitle: 'Insert section', // What should the repeating title be?
    info:
      repeatingSectionNode.getAttribute('title').textContent != undefined
        ? repeatingSectionNode.getAttribute('title').textContent
        : '',
    entities: convertChildSectionFields(repeatingSectionNode, 'Repeating Section'),
    numberOfNestedConditionals: null,
    skipToSummaryConditions: null,
  };
};

const convertSubsection = (subsectionNode: any) => {
  const steps = [];
  let entities = [];

  for (const child of subsectionNode.children) {
    if (ignoredElement(child)) {
      continue;
    }
    const entity = tryToConvertAsSimpleField(child);
    if (!(entity.type == '' && entity.id == '')) {
      entities.push(entity);
    } else {
      if (entities.length > 0) {
        const step = {
          info:
            subsectionNode.getAttribute('title').textContent != undefined
              ? subsectionNode.getAttribute('title').textContent
              : '',
          conditions: null,
          entities: entities,
          repeatable: false,
          repeatableTitle: '',
          numberOfNestedConditionals: null,
          skipToSummaryConditions: null,
        };
        if (step) {
          steps.push(step);
        }
        entities = [];
      }
      const step: any = tryToConvertAsComplexField(child);
      if (step) {
        return step;
      } else {
        // console.log('Unknown subsection child node type: ' + child.nodeName);
      }
    }
  }

  if (steps.length < 1) {
    const step = {
      info:
        subsectionNode.getAttribute('title').textContent != undefined
          ? subsectionNode.getAttribute('title').textContent
          : '',
      conditions: null,
      entities: entities,
      repeatable: false,
      repeatableTitle: '',
      numberOfNestedConditionals: null,
      skipToSummaryConditions: null,
    };
    console.log(step);
    return step;
  }

  return steps;
};

const convertFieldset = (fieldsetNode: any) => {
  const info: string = fieldsetNode.getAttribute('legend').textContent;
  return {
    info: info || '',
    conditions: null,
    entities: convertChildSectionFields(fieldsetNode, 'Fieldset'),
    numberOfNestedConditionals: null,
    skipToSummaryConditions: null,
    repeatable: false,
    repeatableTitle: '',
  };
};

const tryToConvertAsComplexField = (child: any) => {
  let step;
  if (child.nodeName === 'Fieldset') {
    step = convertFieldset(child);
  } else if (child.nodeName === 'Subsection') {
    step = convertSubsection(child);
  } else if (child.nodeName === 'RepeatingSection') {
    step = convertRepeatingSection(child);
  } else if (child.nodeName === 'RepeatingTable') {
    step = convertRepeatingTable(child);
  } else if (child.nodeName === 'PairedFieldset') {
    step = convertPairedFieldsetNode(child);
  } else if (child.nodeName === 'FindPartyTable') {
    step = convertFindPartyTableNode(child);
  }

  return step;
};

const convertSection = (sectionNode: any) => {
  let steps = [];
  let entities = [];
  for (const child of sectionNode.children) {
    if (ignoredElement(child)) {
      continue;
    }
    const entity = tryToConvertAsSimpleField(child);
    if (!(entity.type == '' && entity.id == '')) {
      entities.push(entity);
    } else {
      if (entities.length > 0) {
        const step = {
          info: '',
          conditions: null,
          entities: entities,
          repeatable: false,
          repeatableTitle: '',
          numberOfNestedConditionals: null,
          skipToSummaryConditions: null,
        };
        steps.push(step);
        entities = [];
      }

      const step = tryToConvertAsComplexField(child);
      if (step) {
        if (step.length > 0) {
          for (let i = 0; i < step.length; i++) {
            steps.push(step[i]);
          }
        } else {
          steps.push(step);
        }
      } else {
        // console.log('Unknown node type: ' + child.nodeName);
      }
    }
  }

  // Filter out empty arrays or falsy values
  steps = steps.filter((step: any) => step && step.length !== 0);

  return {
    title: sectionNode.getAttribute('title'),
    steps: steps,
  };
};

const convertView = (viewNode: any) => {
  const sections = [];
  for (const child of viewNode.children) {
    if (child.nodeName === 'Section') {
      const section = convertSection(child);
      sections.push(section);
    } else if (child.nodeName === 'DisplayProperties') {
      // Nothing to convert
    } else {
      // console.log('Unknown node type: ' + child.nodeName);
    }
  }

  return {
    sections: sections,
  };
};

export default convertView;
