import convertQuestions from './QuestionsConverter';
import convertView from './ViewConverter';

const getMandatoryElement = (element: Document | Element, name: string) => {
  const elements = Array.from(element.getElementsByTagName('*')).filter((el: any) =>
    el.tagName.includes(name),
  );

  if (elements.length === 0) {
    throw new Error(`Element ${name} is mandatory`);
  }

  return elements[0];
};

export const convertTemplate = (data: any) => {
  if (data == '') return;
  const xmlString = data;
  const moFormXmlDocument = new DOMParser().parseFromString(xmlString, 'text/xml');

  const templateMetadata: any = getMandatoryElement(moFormXmlDocument, 'TemplateMetaData');
  console.log(templateMetadata);
  const model = getMandatoryElement(moFormXmlDocument, 'Model');
  console.log(model);
  const formModel: any = {
    id: '',
    version: '',
    name: '',
    layout: {
      sections: [],
    },
    questions: [],
  };

  const name = getMandatoryElement(templateMetadata, 'Title');
  const schemaVersion = getMandatoryElement(templateMetadata, 'Version');
  formModel.name = name.textContent ? name.textContent : '';
  formModel.version = schemaVersion.textContent ? schemaVersion.textContent : '';
  console.log(formModel.name);

  const viewsNode = getMandatoryElement(moFormXmlDocument, 'Views');
  const designView = getMandatoryElement(viewsNode, 'ns4:ScreenView');

  const questions = convertQuestions(model, designView);
  formModel.questions = questions;

  const convertedView = convertView(designView);
  formModel.layout.sections = convertedView.sections;

  return formModel;
};
