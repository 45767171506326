import http from './http-common';
import { RootModel } from '../models/formSchema/root';
import { RootRequestModel } from '../models/formSchemaRequest/root-request';
import { ChunkFormData } from '../models/formSchema/chunk-form-data';

class FormTemplateService {
  get(id: string) {
    return http.get<RootModel>(`/FormTemplates/${id}`);
  }

  subjectless(id: string, tenantId: string) {
    return http.get<RootModel>(`/FormTemplates/subjectless/${tenantId}/${id}`);
  }

  put(id: string, body: RootModel) {
    return http.put<RootModel>(`/FormTemplates/${id}`, body);
  }

  post(body: RootRequestModel) {
    return http.post<RootRequestModel>('/FormTemplates', body);
  }

  uploadChunk(chunkData: ChunkFormData) {
    return http.post<ChunkFormData>('/FormTemplates/uploadChunk', chunkData);
  }

  delete(id: string, tenantId: string) {
    return http.delete<RootModel>(`/FormTemplates/${tenantId}/${id}`);
  }
}

export default new FormTemplateService();
